<style scoped>
.v-toolbar {
  display: contents;
}
.violator {
  line-height: 1.47059;
  font-weight: 400;
  letter-spacing: -0.022em;
  color: #fffdb8;
  background: transparent;
  border: 1px solid #fffdb8;
  border-radius: 4px;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 4px;
  padding-bottom: 4px;
  white-space: nowrap;
  font-size: 1rem;
}
.mobile-nav-toggle {
  display: none;
}
@media screen and (max-width: 320px) {
}
@media screen and (max-width: 815px) {
  .mobile-nav-toggle {
    display: flex;
  }
  .nav-main {
    display: none;
  }
  .v-application .headline {
    font-size: 1rem !important;
  }
}
@media screen and (max-width: 1024px) {
}
</style>
<template>
  <v-app>
    <Alerts
      v-bind:alertType="alertType"
      v-bind:message="messageAlert"
      v-on:showAlert="onAlert"
      @clear-Alert="clearAlert"
    ></Alerts>
    <div class="chip-icon"></div>
    <v-app-bar app v-if="showBar">
      <v-toolbar-title
        class="headline text-uppercase"
        style="display: flex; height: -webkit-fill-available"
      >
      <!--
        <div class style="float: left; width: 64px; padding-right: 10px">
          <img
            src="https://angelenologin-static.lacity.org/img/icons/login/CitySeal.svg"
            class="auth-org-logo"
            style="height: 90%"
          />
        </div>
        -->
        <div
          style="
            align-items: center;
            justify-content: center;
            display: flex;
            font-family: 'Oswald' !important;
            font-weight: 900;
          "
        >
          <span style="padding-right: 5px">Angeleno Account</span>
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text @click="home" class="btn-title-bar nav-main">
        <v-icon left dark>mdi-home</v-icon>Home
      </v-btn>
           <!-- This is for the Apps & Site/Services has turned to just Services 5.17.2022 -->
       <v-btn text @click="apps" class="btn-title-bar nav-main">
        <v-icon left dark>mdi-apps</v-icon>Services
      </v-btn>

      <v-btn text @click="help" class="btn-title-bar nav-main">
        <v-icon left dark>{{ icons.mdiHelpCircle }}</v-icon
        >Help
      </v-btn>
     
     
     <!-- Disabled 4.15.2021 - Alfredo Zanchez
      <v-btn
        text
        @click="dashboard"
        class="btn-title-bar nav-main"
        v-if="authenticated"
      >
        <v-icon left dark>{{ icons.mdiNotebookMultiple }}</v-icon
        >Dashboard
      </v-btn>   
-->
      <router-link
        tag="button"
        id="login-button"
        to="/home"
        v-if="!authenticated"
      >
      
        <v-btn text class="btn-title-bar nav-main"
          ><v-icon left dark>mdi-login</v-icon>Login</v-btn
        >
      </router-link>
      <div v-if="authenticated">
        <ProfileButton
          v-bind:userinfo="userinfo"
          @ShowAlert="onAlert"
          class="btn-title-bar nav-main"
        ></ProfileButton>
      </div>
      <v-app-bar-nav-icon
        @click="drawer = true"
        class="btn-title-bar mobile-nav-toggle"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group active-class="deep-blue--text text--accent-4">
          <v-list-item @click="home">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <!-- This is for the Apps & Site/Services has turned to just services 5.17.2022 -->
          <v-list-item @click="apps">
          
            <v-list-item-icon>
              <v-icon>mdi-apps</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Services</v-list-item-title>
          
          </v-list-item>

          <v-list-item @click="help">
            <v-list-item-icon>
              <v-icon>{{ icons.mdiHelpCircle }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Help</v-list-item-title>
          </v-list-item>

          <v-list-item @click="account" v-if="!authenticated">
            <v-list-item-icon>
              <v-icon>mdi-login</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item>

          <v-list-item @click="showProfile" v-if="authenticated">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>View/Edit Profile</v-list-item-title>
          </v-list-item>

          <v-list-item @click="changePassword" v-if="authenticated">
            <v-list-item-icon>
              <v-icon>mdi-lock-reset</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Change Password</v-list-item-title>
          </v-list-item>


<!--
          <v-list-item @click="dashboard" v-if="authenticated">
            <v-list-item-icon>
              <v-icon>{{ icons.mdiNotebookMultiple }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>
-->
          <v-list-item @click="logout" v-if="authenticated">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>

          <!-- <v-list-item class="chipbtn">
            <v-list-item-icon>
              <span aria-hidden="true" class="v-icon notranslate v-icon--svg theme--light"><ChipLogo/></span>
            </v-list-item-icon>
            <v-list-item-title>Chip</v-list-item-title>
          </v-list-item>-->
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    
    <div style="padding: 17px"> 

 <v-banner 

      color="#ffc107"
       rounded
       dark
       elevation="23">
       <h5 style="color: black;">A new look is coming soon.</h5>
      <p style="color: black;">Angeleno Account is improving its website and sign-in page with a more modern look and feel.</p>


     
<!--
      <template v-slot:actions>
        <v-btn
          text
          color="deep-purple accent-4">
          Get Online
        </v-btn>
      </template>
    -->
    </v-banner>
  



    </div>
   

    <Hero v-if="displayHero" />
    <v-main style="padding: 0">

   

      <v-container class="main-container">
        <v-row align="center" justify="center">
          <router-view @ShowAlert="onAlert" />
        </v-row>



        
      </v-container>
    </v-main>

    <footer class="footer" id="footer" v-if="showFooter">
      <div class="container d-flex justify-content-center">
        <div id="lacity-copyright" class="col-lg-8 col-md-8 my-md-4">
          <p class="pr-4 pt-2">

            © Copyright {{ currentYear }} City of Los Angeles. All rights
            reserved.&nbsp;&nbsp;
            <a
              href="http://disclaimer.lacity.org/disclaimer.htm"
              target="_blank"
              >Disclaimer</a
            >
            |
            <a href="http://disclaimer.lacity.org/privacy.htm" target="_blank"
              >Privacy Policy</a
            >
          </p>
        </div>
      </div>
    </footer>
  </v-app>
</template>

<script>
import ProfileButton from "@/components/ProfileButton";
//import ChipLogo from './assets/svg/chip.svg';
import { mdiNotebookMultiple } from "@mdi/js";
import { mdiHelpCircle } from "@mdi/js";
import Alerts from "@/components/Alerts";
import Hero from "@/components/Hero";

export default {
  name: "App",
  data() {
    return {
      elevation: 10,
      width: "50%",
      loginRedirect: true,
      authenticated: false,
      CAauthenticated: false,
      CAstate: "",
      CAstreetAddress: "",
      CAcity: "",
      userinfo: undefined,
      key: 0,
      drawer: false,
      icons: {
        mdiNotebookMultiple,
        mdiHelpCircle,
      },
      alertType: "",
      messageAlert: "",
      accountVerified: this.$session.get("accountVerified"),
      currentYear: null,
      displayHero: false,
      showBar: true,
      showFooter: true,
    };
  },
  components: {
    ProfileButton,
    Hero,
    // ChipLogo,
    Alerts,
  },
  created() {
    this.appInit();
  },
  watch: {
    // Everytime the route changes, check for auth status
    $route: "isAuthenticated",
    accountVerified: function () {
      this.$session.set("accountVerified", this.accountVerified);
    },
  },
  methods: {
    appInit() {

 try {
        this.displayHero = this.$router.history.current.meta.showHero;
  
        const loc = window.location.href;
        //console.log(loc);
//console.log(window.location.href);
        if ((loc.includes("auth/callback") || loc.includes("ACTIVATION")) && (!loc.includes("code")) ){
        //console.log("Ooo, we got the value!");
        this.$router.push({name: "verification-home",});
        }
      } catch {
        console.log("Maybe no need to redirect?");
      }



      try {
        this.displayHero = this.$router.history.current.meta.showHero;
      } catch {
        console.log("");
      }
      var d = new Date();
      this.currentYear = d.getFullYear();
      if (this.$config.oidc.redirect_uri)
        this.loginRedirect = this.$config.oidc.redirect_uri;
      this.isAuthenticated();
    },
    async isAuthenticated(e) {
      try {
        this.displayHero = e.meta.showHero;
        this.showBar = e.meta.showBar;
        this.showFooter = e.meta.showFooter;
      } catch {
        console.log(""); // Empty string??
      }
      this.authenticated = await this.$auth.isAuthenticated();

      if (!this.userinfo) {
        // hacky-hack. Since we're directly updating userinfo. Do not fetch new data because there may be a race-condition and data from Okta could lag the UI
        this.userinfo = await this.$auth.getUser();

  try {
        this.CAstate = (this.userinfo.state);
        this.CAstreetAddress =  (this.userinfo.postalAddress);
        this.CAcity = this.userinfo.city; 
  } catch (e) {
    //console.log(e);
        this.CAstate ="";
        this.CAstreetAddress =  "";
        this.CAcity = "";
  }


        if(this.CAstate.toLowerCase() === "california")
        {
          this.CAauthenticated = true;
          //console.log("Value for CAAuthenticated: " + this.CAauthenticated);
          
        }

      }
    },
    home() {
      window.location.href = process.env.VUE_APP_REDIRECTED_HOME;
      /*this.$router.push({
        name: "home2",
      });*/
    },
    apps() {
      //this.$router.push({ name: 'apps', params: { caauth: this.CAauthenticated, address: (this.CAstreetAddress +","+ this.CAcity) } });
      window.location.href = process.env.VUE_APP_REDIRECTED_SERVICES;
    },
   /* dashboard() {
      this.$router.push({
        name: "dashboard",
      });
    },*/
    account() {
      this.$router.push({
        name: "settings",
      });
    },
    help() {
      window.location.href = process.env.VUE_APP_REDIRECTED_HELP;
      /*this.$router.push({
        name: "help",
      });*/
    },
    async logout() {
      this.token = await this.$auth.getIdToken();
      await this.$auth.logout();
      window.location.href =
        process.env.VUE_APP_ISSUER +
        "/v1/logout?id_token_hint=" +
        this.token +
        "&post_logout_redirect_uri=" +
        this.origin;
    },
    showProfile() {
      this.$router.push({
        name: "settings",
        params: {
          focusTab: 0,
        },
      });
    },
    changePassword() {
      this.$router.push({
        name: "settings",
        params: {
          focusTab: 1,
        },
      });
    },
    onAlert: function (obj) {
      this.alertType = obj.typeAlert;
      this.messageAlert = obj.messageAlert;
    },
    clearAlert: function () {
      this.alertType = "";
      this.messageAlert = "";
    },
    requiredAcctVerification() {
      return this.$root.$children[0].accountVerified;
    },
  },
};
</script>
<style>
.v-toolbar__extension {
  background-color: #03a751;
  color: white !important;
  font-family: "Oswald" !important;
  font-weight: 500 !important;
}
.btn-title-bar {
  color: white !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
}
.v-toolbar__content {
  display: flex;
  display: -webkit-box;
}
</style>