import Vue from 'vue'
import Router from 'vue-router'
import Auth from '@okta/okta-vue'
//import Dashboard from '@/views/Home.vue'
import Angeleno from '@/views/Angeleno.vue'
//import AngelenoLogin from '@/views/AngelenoLogin.vue'
import Settings from '@/views/Settings.vue'
//import Help from '@/views/Help.vue'
//import LoginComponent from '@/components/Login'
//import ContactUs from '@/views/ContactUs.vue'
import AcctVerification from '@/components/AcctVerification'
//import AngelenoAppLogin from '@/components/AngelenoAppLogin'
//import LogoutComponent from '@/components/Logout'
import config from '@/.config'
import axios from "axios";
//import apps from '@/views/AppsSites'

Vue.use(Router)
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Angeleno,
      meta: {
        showHero: true,
        showBar: true,
        showFooter: true
      }
    },
    {
      path: '/home',
      name: 'verification-home',
      component: Angeleno,
      meta: {
        requiresAuth: true,
        showHero: true,
        showBar: true,
        showFooter: true
      }
    },
    {
      path: '/apps',
      beforeEnter() { //Added redirection to new Angeleno Site - AZ 2.25.2025 
        window.location.href = process.env.VUE_APP_REDIRECTED_SERVICES;
      },
    },
    {
      path: '/help',
      beforeEnter() { //Added redirection to new Angeleno Site - AZ 2.25.2025 
        window.location.href = process.env.VUE_APP_REDIRECTED_HELP;
      },
    },
    {
      path: '/contact-us',
      beforeEnter() { //Added redirection to new Angeleno Site - AZ 2.25.2025 
        window.location.href = process.env.VUE_APP_REDIRECTED_HELP;
      },
    },
    /*{
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: {
        requiresAuth: true,
        showHero: false,
        showBar: true,
        showFooter: true
      }
    },*/
    {
      path: '/acctVerification',
      name: 'acctVerification',
      component: AcctVerification,
      meta: {
        requiresAuth: true,
        showHero: false,
        showBar: true,
        showFooter: true
      }
    },
    /*{
      path: '/login',
      component: LoginComponent,
      meta: {
        showHero: false,
        showBar: true,
        showFooter: true
      }
    },*/
    /*{
      path: '/logout',
      component: LogoutComponent,
      meta: {
        showHero: false,
        showBar: true,
        showFooter: true
      }
    },*/
    /*{
      path: '/Angelenologin',
      component: AngelenoLogin,
      meta: {
        requiresAuth: true,
        showHero: false,
        showBar: true,
        showFooter: true
      }
    },*/
    {
      path: '/oauth/callback',
      component: Auth.handleCallback()
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      props: true,
      meta: {
        requiresAuth: true,
        AcctVerificationRequired: true,
        showHero: false,
        showBar: true,
        showFooter: true

      }
    },
    /*{
      path: '/AngelenoAppLogin/:id/:snowPortalRoute?',
      component: AngelenoAppLogin,
      meta: {
        requiresAuth: true,
        showHero: false,
        showBar: false,
        showFooter: false
      }
    }*/
      { // Catch-all route for unknown paths AZ 2.25.2025
        path: '*',
        beforeEnter() {
          window.location.href = process.env.VUE_APP_REDIRECTED_HOME; // Redirect to Home Page for any unknown paths
        }
      }
  ]
})

config.oidc.redirect_uri = window.location.protocol + '//' + window.location.host + config.oidc.redirect_uri

Vue.use(Auth, {
  issuer: config.oidc.issuer,
  client_id: config.oidc.client_id,
  redirect_uri: config.oidc.redirect_uri,
  scopes: config.oidc.scope.split(' '),
  pkce: true,
  tokenManager: config.oidc.tokenManager
})

const onAuthRequired = async (from, to, next) => {
  if (from.matched.some(record => record.meta.requiresAuth) && !(await Vue.prototype.$auth.isAuthenticated())) {
    if (config.loginRedirect) {
      Vue.prototype.$auth.loginRedirect(from.fullPath)
    } else {
      next({ path: '/login' })
    }
  } else if (from.matched.some(record => record.meta.AcctVerificationRequired) && !await AcctVerificationRequired()) {
    next({
      name: 'acctVerification',
      query: { redirectFrom: from.fullPath }
    })
  } else {
    next()
  }
}
router.beforeEach(onAuthRequired)

async function AcctVerificationRequired() {
  const url = `${Vue.prototype.$config.api}/api/authn/v1/authn/` + Vue.prototype.$session.get("acctVerification");
  const accessToken = await Vue.prototype.$auth.getAccessToken();
  try {
    const res = await axios.get(url, {
      headers: { Authorization: "Bearer " + accessToken }
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
  return false;
}


export default router